<template>
  <div class="report-tabs">
    <div
      class="report-tab"
      :class="{ active: activeTab === tabs.MERCHANTS }"
      @click="activeTab = tabs.MERCHANTS"
    >
      <div class="icon-wrap">
        <AppIcon name="shop" size="18px" is-img-tag />
      </div>

      <AppText size="14px" :line-height="1.5">
        Merchants
      </AppText>

      <transition name="show">
        <div v-if="activeTab === tabs.MERCHANTS" class="check">
          <AppIcon name="check" size="8px" />
        </div>
      </transition>
    </div>
    <div
      class="report-tab"
      :class="{ active: activeTab === tabs.INVOICES }"
      @click="activeTab = tabs.INVOICES"
    >
      <div class="icon-wrap">
        <AppIcon name="doc-bill-invoice" size="18px" is-img-tag />
      </div>

      <AppText size="14px" :line-height="1.5">
        Invoices
      </AppText>

      <transition name="show">
        <div v-if="activeTab === tabs.INVOICES" class="check">
          <AppIcon name="check" size="8px" />
        </div>
      </transition>
    </div>

    <div
      class="report-tab"
      :class="{ active: activeTab === tabs.DEPOSITS }"
      @click="activeTab = tabs.DEPOSITS"
    >
      <div class="icon-wrap">
        <AppIcon name="coins-stacked" size="18px" is-img-tag />
      </div>

      <AppText size="14px" :line-height="1.5">
        Deposits
      </AppText>

      <transition name="show">
        <div v-if="activeTab === tabs.DEPOSITS" class="check">
          <AppIcon name="check" size="8px" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { tabs } from '../data';

const activeTab = defineModel({ type: String, default: tabs.MERCHANTS });
</script>

<style lang="scss" scoped>
.report-tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 40px;

  .report-tab {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    width: 160px;
    height: 102px;
    border: 1px solid var(--color-black-01);
    border-radius: 8px;
    position: relative;

    &:hover, &.active {
      border: 1px solid var(--color-primary);
      background-color: var(--color-FBF3EC);
    }

    .icon-wrap {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: var(--color-primary-003);
      @include flex-center;
    }

    .check {
      position: absolute;
      top: -6px;
      right: -6px;
      background: var(--color-primary);
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 2px solid var(--color-white);
      @include flex-center;

      :deep(.icon path) {
        fill: var(--color-white);
      }
    }
  }
}
</style>
