import dayjs from 'dayjs';

import { useAdmin } from '@/composables/useAdmin';
import { useFetch } from '@/api';
import { downloadAsFile } from '@/utils/functions';

const { dateFormat } = useAdmin();

export const getReportsStartYears = async () => {
  const { axiosWrapper, successHandler } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: '/admin/reports/start-years',
  });
  return { data, isSuccess, successHandler };
};

export const getMerchantsReportEmails = async (params) => {
  await useReports({
    params,
    url: '/admin/reports/merchants-emails',
    filename: `merchants_emails_report_${dayjs().format(dateFormat.value)}`,
    type: 'txt',
  });
};

export const getMerchantsReport = async (params) => {
  await useReports({
    params,
    url: '/admin/reports/merchants',
    filename: `merchants_report_${dayjs().format(dateFormat.value)}`,
  });
};

export const getInvoicesReport = async (params) => {
  await useReports({
    params,
    url: '/admin/reports/invoices',
    filename: `invoices_report_${dayjs().format(dateFormat.value)}`,
  });
};

export const getDepositsReport = async (params) => {
  await useReports({
    params,
    url: '/admin/reports/deposits',
    filename: `deposits_report_${dayjs().format(dateFormat.value)}`,
  });
};

const useReports = async ({
  params,
  url,
  filename,
  type,
}) => {
  const filteredObj = Object.keys(params).filter((key) => Boolean(params[key]) !== false).reduce((acc, key) => {
    acc[key] = params[key];
    delete acc.type;
    return acc;
  }, {});

  const queryParams = new URLSearchParams(filteredObj).toString();
  const { axiosWrapper } = useFetch();

  const response = await axiosWrapper({
    type: 'get',
    url: queryParams ? `${url}?${queryParams}` : url,
    responseType: 'blob',
    params: { isFreshResponse: true },
  });

  if (response?.result?.data) {
    downloadAsFile({
      data: response.result.data,
      filename,
      type: type || 'html',
    });
  }
};
