<template>
  <div class="reports">
    <AppText size="17px" class="font-medium" variant="div" mb="20px">
      Select collection
    </AppText>

    <ReportTabs v-model="activeTab" />

    <SimpleTransition>
      <ReportTabsItem
        v-if="activeTab === tabs.MERCHANTS"
        years-title="Select registration period"
        is-type-visible
        :is-loading="isMerchantsReportLoading"
        :start-year="reportsStartingYear?.merchants || new Date().getFullYear()"
        :options="[
          {
            deletedMerchants: {
              value: false,
              text: 'Include deleted merchants',
            },
          },
        ]"
        @generate="onGenerateMerchantsReport"
      />

      <ReportTabsItem
        v-if="activeTab === tabs.INVOICES"
        years-title="Select billing period"
        :is-loading="isInvoicesReportLoading"
        :start-year="reportsStartingYear?.invoices || new Date().getFullYear()"
        :options="[
          {
            zeroCharge: {
              value: false,
              text: 'Include zero-charge invoices',
            },
          },
        ]"
        @generate="onGenerateInvoicesReport"
      />

      <ReportTabsItem
        v-if="activeTab === tabs.DEPOSITS"
        years-title="Select monitoring period"
        :is-loading="isDepositsReportLoading"
        :start-year="reportsStartingYear?.deposits || new Date().getFullYear()"
        :options="[
          {
            unConfirmed: {
              value: false,
              text: 'Include unconfirmed deposits',
            },
          },
          {
            isCredit: {
              value: false,
              text: 'Include manually credited deposits',
            },
          },
        ]"
        @generate="onGenerateDepositsReport"
      />
    </SimpleTransition>
  </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue';

import SimpleTransition from '@/components/Animation/SimpleTransition.vue';

import {
  getDepositsReport,
  getInvoicesReport,
  getMerchantsReport,
  getMerchantsReportEmails,
  getReportsStartYears,
} from '@/api/admin/reports';

import { tabs } from './data';
import ReportTabs from './components/ReportTabs.vue';
import ReportTabsItem from './components/ReportTabsItem.vue';

export default {
  name: 'AdminReports',
  components: {
    SimpleTransition,
    ReportTabs,
    ReportTabsItem,
  },
  setup() {
    const activeTab = ref(tabs.MERCHANTS);

    const reportsStartingYear = ref({});

    const isMerchantsReportLoading = ref(false);
    const onGenerateMerchantsReport = async (data) => {
      isMerchantsReportLoading.value = true;
      if (data.type === 'emails') {
        await getMerchantsReportEmails(data);
      } else {
        await getMerchantsReport(data);
      }
      isMerchantsReportLoading.value = false;
    };

    const isInvoicesReportLoading = ref(false);
    const onGenerateInvoicesReport = async (data) => {
      isInvoicesReportLoading.value = true;
      await getInvoicesReport(data);
      isInvoicesReportLoading.value = false;
    };

    const isDepositsReportLoading = ref(false);
    const onGenerateDepositsReport = async (data) => {
      isDepositsReportLoading.value = true;
      await getDepositsReport(data);
      isDepositsReportLoading.value = false;
    };

    onBeforeMount(() => {
      getReportsStartYears().then((res) => {
        reportsStartingYear.value = res.data.reportsStartingYear;
      });
    });

    return {
      activeTab,
      tabs,
      reportsStartingYear,
      onGenerateMerchantsReport,
      onGenerateInvoicesReport,
      onGenerateDepositsReport,

      isMerchantsReportLoading,
      isInvoicesReportLoading,
      isDepositsReportLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.reports {
  padding: 40px;
}
</style>
