<template>
  <div class="report-item">
    <div class="body">
      <div class="body-item">
        <AppText class="font-medium">
          {{ yearsTitle }}
        </AppText>
        <div class="years">
          <AppText
            variant="div"
            class="year-button"
            :class="{ active: !selectedYear }"
            @click="selectedYear = null"
          >
            All time
          </AppText>
          <div
            v-for="year in years"
            :key="year"
            class="year-button"
            :class="{ active: selectedYear === year }"
            @click="selectedYear = year"
          >
            <AppText>
              <template v-if="year === dayjs().year()">
                This year
              </template>

              <template v-else-if="year === (dayjs().year() - 1)">
                Last year
              </template>

              <template v-else>
                {{ year }}
              </template>
            </AppText>
          </div>
        </div>
      </div>

      <div v-if="isTypeVisible" class="body-item">
        <AppText class="font-medium">
          Type
        </AppText>

        <FRadioButton
          v-model="type"
          :options="typeOptions"
        />
      </div>

      <div class="body-item" style="gap: 0;">
        <AppText class="font-medium" variant="div" mb="10px">
          Optional
        </AppText>

        <div v-for="item in optionalOptions" :key="item.key" class="optional" @click.prevent="toggle(item)">
          <FCheckbox :model-value="item[Object.keys(item)[0]].value" />
          <AppText>{{ item[Object.keys(item)[0]].text }}</AppText>
        </div>
      </div>
    </div>

    <FButton type="primary" :loading="isLoading" @click="onGenerate">
      Generate report
    </FButton>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import dayjs from 'dayjs';

const props = defineProps({
  yearsTitle: {
    type: String,
    required: true,
  },
  startYear: {
    type: Number,
    default: dayjs()
      .year(),
  },
  isTypeVisible: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['generate']);

const years = computed(() => {
  const res = [];

  for (let i = dayjs().year(); i >= props.startYear; i--) {
    res.push(i);
  }

  return res;
});

const selectedYear = ref(null);

const type = ref('full');

const typeOptions = ref([
  { text: 'Full Report', value: 'full' },
  { text: 'Emails Only', value: 'emails' },
]);

const optionalOptions = ref(props.options);

const onGenerate = () => {
  const obj = {};

  for (let i = 0; i < optionalOptions.value.length; i++) {
    const key = Object.keys(optionalOptions.value[i])[0];
    obj[key] = optionalOptions.value[i][key].value;
  }

  const data = {
    year: selectedYear.value,
    type: props.isTypeVisible ? type.value : null,
    ...obj,
  };

  emit('generate', data);
};

const toggle = (item) => {
  optionalOptions.value[optionalOptions.value.indexOf(item)][Object.keys(item)[0]].value = !item[Object.keys(item)[0]].value;
};
</script>

<style lang="scss" scoped>
.report-item {
  .body {
    border-bottom: 1px solid var(--color-black-01);
    margin-bottom: 30px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .body-item {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

.years {
  display: flex;
  align-items: center;
  gap: 12px;

  .year-button {
    cursor: pointer;
    height: 32px;
    padding: 0 12px;
    background: var(--color-F7F7F7);
    border-radius: 9999px;
    white-space: nowrap;
    @include flex-center;
    @include transition-base('color, background');

    &:hover, &.active {
      color: var(--color-primary);
    }

    &.active {
      background: var(--color-primary-01);
    }
  }
}

.optional {
  height: 36px;
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding-right: 16px;
}
</style>
